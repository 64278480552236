@import 'styles/variables/colors';

html {
	background-color: $cream;
	min-height: 100vh;
}

body {
	padding-top: 72px;
	min-height: calc(100vh - 72px);
	display: flex;
	flex-direction: column;
}

#root {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.App {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}