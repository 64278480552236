.cdn-image.placeholder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, rgba(197, 197, 197, 0.5), rgba(230, 230, 230, 0.5));
    background-size: 100% 100%;
    
    @function swiper-color($opacity) {
        @return rgba(255, 255, 255, $opacity);
    }
    
    .swiper {
        transform: rotate(35deg);
        width: 150%;
        height: 300%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
        background: linear-gradient(90deg, swiper-color(0) 0%, swiper-color(0.75) 50%, swiper-color(0) 100%);
        animation: cdn-placeholder-animation 1.5s infinite ease-out;
    }
}

@keyframes cdn-placeholder-animation {
    0% {
        left: -175%;
    }
    100% {
        left: 225%;
    }
}