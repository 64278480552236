@import '../../../styles/variables/colors';
@import '../../../styles/mixins/fonts';
@import '../../../styles/mixins/gradient_button';

.login-block, .login-block form {
	display: flex;
	flex-direction: column;
	background-color: $cream;
	@include comfortaa;
}

.login-block {
	width: calc(100% - 44px);
	max-width: 500px;
	min-height: 435px;
	border-radius: 20px;
	box-shadow: 0 12.5px 15px -7.5px rgba(0, 0, 0, 0.25);
	color: $black;
	
	h1 {
		font-size: 24px;
		text-align: center;
		margin-top: 22px;
		margin-bottom: 15px;
	}
	
	form {
		padding: 0 33px;
		
		label {
			display: flex;
			flex-direction: column;
			margin-top: 35px;
			
			span {
				margin-left: 5px;
				font-size: 18px;
			}
		}
		
		input {
			margin-top: 10px;
			border-radius: 200px;
			height: 40px;
			border: none;
			outline: none;
			padding: 0 20px;
			font-size: 16px;
			@include comfortaa;
			
			transition: 0.25s ease-in-out;
		}
		
		input:focus {
			outline: 2px solid black;
		}
		
		button {
			@include gradient_button();
		}
	}
	
	#login-error {
		color: red;
		font-size: 16px;
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

