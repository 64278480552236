@import "src/styles/variables/colors";
@import "src/styles/mixins/flex";


.album-image-edit {
	$radius: 10px;
	@include flex(column, flex-start, center);
	position: relative;
	grid-row-end: span 1;
	background-color: white;
	border-radius: $radius;
	
	box-shadow: rgba(0, 0, 0, 0.25) 0 15px 15px -15px;
	
	.delete-img-btn {
		position: absolute;
		top: 0; right: 0;
		width: 44px;
		height: 44px;
		background-color: $red;
		border: none;
		border-radius: 0 $radius 0 $radius;
		padding: 5px;
		cursor: pointer;
		opacity: 0;
		transition: all 0.3s;
		
		.delete-icon {
			fill: white;
			width: 100%;
			height: 100%;
			aspect-ratio: 1/1;
		}
	}
	
	&:hover .delete-img-btn {
		opacity: 1;
	}
	
	.image-preview {
		width: 100%;
		
		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			border-radius: $radius $radius 0 0;
		}
	}
	
	.album-image-edit-inputs {
		@include flex(column, space-evenly, center);
		
		width: 100%;
		padding-bottom: 10px;
		
		.file-name-input {
			font-size: 1.2rem;
			text-align: center;
			color: $black;
			background-color: transparent;
			border: none;
			border-bottom: 1px solid $black;
			margin: 10px 0;
			max-width: 90%;
			width: 90%;
		}
		
		.file-name-input:focus {
			outline: none;
		}
	}
	
	@media (min-width: 700px) {
	
	}
}
