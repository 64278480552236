$cream: #FAF0E8;
$dark-cream: darken($cream, 5%);
$black: #050505;
$red: #e74c3c;
$green: #37ae28;

$g-orange: rgb(237, 162, 89);
$g-purple: rgb(80, 59, 186);

@function photo-gradient($angle: 90deg) {
	@return linear-gradient($angle, $g-orange, $g-purple);
}