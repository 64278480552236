@import "src/styles/mixins/fonts.scss";
@import "src/styles/variables/colors.scss";

.upload-images-page {
	@include comfortaa;
	
	h1 {
		font-size: 1.5rem;
		color: $black;
		margin: 20px 0;
		text-align: center;
	}
}