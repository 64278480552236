@import "../../styles/mixins/fonts";
@import "../../styles/mixins/flex";
@import "../../styles/variables/colors";

header {
    
    $header-height: 72px;
    
    @include comfortaa;
    @include flex(row, space-between, center);
    
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(12, 12, 20, 0.5);
    width: 100%;
    height: $header-height;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 1000;
    
    h2 {
        color: white;
        padding: 0;
        font-size: 1em;
        line-height: $header-height;
        margin: 0 0 0 24px;
    }
    
    .user-infos {
        @include flex(row, flex-end, center);
        flex-wrap: wrap;
        max-width: 50%;
        
        & > * {
            margin-right: 7px;
        }
    }
}

@media (min-width: 768px) {
    header {
        h2 {
            font-size: 1.5em;
        }
    }
    
}