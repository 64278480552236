.view-image-page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	.image-container {
		width: 90%;
		height: 82vh;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: visible;
		
		.cdn-image {
			object-fit: scale-down;
			object-position: center;
			filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
			width: 100%;
			height: 100%;
		}
	}
}