@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Cormorant+SC:wght@300;400;500;600;700&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@mixin noto {
	font-family: 'Noto Sans', sans-serif;
}

@mixin comfortaa {
	font-family: 'Comfortaa', sans-serif;
}

@mixin cormorant-sc-light {
	font-family: "Cormorant SC", serif;
	font-weight: 300;
	font-style: normal;
}

@mixin cormorant-sc-regular {
	font-family: "Cormorant SC", serif;
	font-weight: 400;
	font-style: normal;
}

@mixin cormorant-sc-medium {
	font-family: "Cormorant SC", serif;
	font-weight: 500;
	font-style: normal;
}

@mixin cormorant-sc-semibold {
	font-family: "Cormorant SC", serif;
	font-weight: 600;
	font-style: normal;
}

@mixin cormorant-sc-bold {
	font-family: "Cormorant SC", serif;
	font-weight: 700;
	font-style: normal;
}
